import React, { useState,useEffect,useRef,forwardRef } from 'react';
import ReactToPrint from "react-to-print";
import styled from 'styled-components';
import numberToWords from 'number-to-words';
import { useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';
import apis from '../../../Api';



// const PrintComponent = ({ children }) => {
//   const printableRef = useRef();

//   const handlePrint = () => {
//     const content = printableRef.current.innerHTML;
//     const printWindow = window.open('', '_blank');
//     printWindow.document.open();
//     printWindow.document.write(`
//       <html>
//         <head>
//           <title>Print Invoice</title>
//           <style>
//             @media print {
//               body * {
//                 visibility: hidden;
//               }
//               #printableArea, #printableArea * {
//                 visibility: visible;
//               }
//               #printableArea {
//                 position: absolute;
//                 left: 0;
//                 top: 0;
//               }
//             }
//           </style>
//         </head>
//         <body>
//           <div id="printableArea">
//             ${content}
//           </div>
//         </body>
//       </html>
//     `);
//     printWindow.document.close();
//     printWindow.print();
//     printWindow.close();
//   };

//   return (
//     <div>
//       <PrintButton onClick={handlePrint}>Print Invoice</PrintButton>
//       <div ref={printableRef}>{children}</div>
//     </div>
//   );
// };


const Invoice = () =>  {
  const [data, setData] = useState('');
  const [invoiceTotalAmount, setInvoiceTotalAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState('');
  const location = useLocation();
  const { state } = location;


  const {invoice_id} = state || {};
 
  
  const navigate = useNavigate(); 
    const [invoiceData, setInvoiceData ] = useState('');
    //  const invoiceData = {
    //     id: "5df3180a09ea16dc4b95f910",
    //     invoice_no: "201906-28",
    //     balance: "$2,283.74",
    //     company: "MANTRIX",
    //     email: "susanafuentes@mantrix.com",
    //     phone: "+1 (872) 588-3809",
    //     address: "922 Campus Road, Drytown, Wisconsin, 1986",
    //     trans_date: "2019-09-12",
    //     due_date: "2019-10-12",
    //     items: [
    //       {
    //         sno: 1,
    //         desc: "ad sunt culpa occaecat qui",
    //         qty: 5,
    //         rate: 405.89,
    //       },
    //       {
    //         sno: 2,
    //         desc: "cillum quis sunt qui aute",
    //         qty: 5,
    //         rate: 373.11,
    //       },
    //       {
    //         sno: 3,
    //         desc: "ea commodo labore culpa irure",
    //         qty: 5,
    //         rate: 458.61,
    //       },
    //       {
    //         sno: 4,
    //         desc: "nisi consequat et adipisicing dolor",
    //         qty: 10,
    //         rate: 725.24,
    //       },
    //       {
    //         sno: 5,
    //         desc: "proident cillum anim elit esse",
    //         qty: 4,
    //         rate: 141.02,
    //       },
    //     ],
    //   };

      const fetchData = (params= {} ) => {
        setLoading(true);
        try {
          const token = localStorage.getItem('billgentoken');
          console.log(invoice_id);
          let values ={
          'token': token,
          'invoice_id' : invoice_id,
          };
          console.log(token);
          axios.post(
            apis.GET_INVOICE,  // Assuming this is your URL
            values,  // No data in this case, since it's a GET request
            {
              headers: {
                // 'Authorization': `${token}`,
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          .then(res => {
            let tableData = res.data;
            console.log(tableData.data.grand_total);
            const invoiceDateTime = tableData.data.invoice_date_time;
            // const totalAmountInt = tableData.data.grand_total;
            // console.log(totalAmountInt);
            // const totalAmountWords = numberToWords(totalAmountInt);
            const [datePart] = invoiceDateTime.split(' ');

            // Splitting the date into year, month, and day components
            const [year, month, day] = datePart.split('-');

            // Creating a formatted date string in DD-MM-YYYY format
            const formattedDate = `${day}-${month}-${year}`;



            // Updating the invoice_date_time field in tableData with the formatted date
            tableData.data.invoice_date_time = formattedDate;
           console.log(tableData.data.user_detail);
           if (tableData.data.user_detail) {
            const userDetail = JSON.parse(tableData.data.user_detail);
            const userSignature = userDetail.user.signature;
            console.log(userSignature);
            if (userSignature instanceof Blob) {
              const reader = new FileReader();
              
              reader.onload = (event) => {
                const imageUrl = event.target.result;
                console.log(imageUrl);
                userDetail.user.signature = imageUrl;
                setInvoiceData({ ...tableData.data, user_detail: JSON.stringify(userDetail) });
              };
          
              reader.readAsDataURL(userSignature); // Start reading the image data
            } else {
              setInvoiceData(tableData.data); // No need to process if it's already an image URL
            }
          } else {
            setInvoiceData(tableData.data); // Handle the case when user_detail is not available
          }
            
              
              // setInvoiceTotalAmount(tableData.data.grand_total);
              setLoading(false);
              setPagination({
                ...params.pagination,
                total: 5,
                });
              })
        } 
        
         catch (err) {
           console.log('Error:', err);
           setLoading(false);
        }
        console.log(invoiceData);
      };

      useEffect(() => {
        fetchData({
          pagination,loading
        });
      }, []);
      
      
      const numberToWords = (num) => {
        const units = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    
        const convert = (num) => {
          if (num < 10) return units[num];
          if (num < 20) return teens[num - 10];
          if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + units[num % 10] : '');
          if (num < 1000) return units[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' and ' + convert(num % 100) : '');
          if (num < 100000) return convert(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 !== 0 ? ' ' + convert(num % 1000) : '');
          if (num < 10000000) return convert(Math.floor(num / 100000)) + ' Lakh' + (num % 100000 !== 0 ? ' ' + convert(num % 100000) : '');
          return convert(Math.floor(num / 10000000)) + ' Crore' + (num % 10000000 !== 0 ? ' ' + convert(num % 10000000) : '');
        };
    
        if (num === 0) return 'Zero Rupees Only';
        return convert(num) + ' Rupees Only';
      };

      const handlePrint = () => {
        const printableContent = document.getElementById('invoice-container');
      
        if (printableContent) {
          const printWindow = window.open('', '_blank');
          if (printWindow) {
            const styles = Array.from(document.styleSheets)
              .map((styleSheet) => {
                try {
                  return Array.from(styleSheet.cssRules)
                    .map((cssRule) => cssRule.cssText)
                    .join('\n');
                } catch (error) {
                  console.log('Error while reading CSS rules:', error);
                  return '';
                }
              })
              .join('\n');
      
            printWindow.document.write(`
              <html>
                <head>
                  <title>Print Invoice</title>
                  <style>
                    ${styles}
      
                    /* Print-specific styles */
                    @media print {
                      body {
                        margin: 0;
                        padding: 0;
                      }
                      #invoice-container {
                        width: 100%;
                        max-width: 100%;
                        margin: 0;
                        padding: 20px;
                        background-color: #f7f7f7;
                        border-radius: 8px;
                        box-sizing: border-box;
                        font-family: Arial, sans-serif;
                      }
                      /* Set page size to A3 */
                      @page {
                    
                        margin: 0;
                        padding: 0;
                        Pages per sheet: 2;
                        -webkit-print-color-adjust: exact;
                      }
                      /* Additional print styles as needed */
                    }
                  </style>
                </head>
                <body>
                  <div id="invoice-container">${printableContent.innerHTML}</div>
                </body>
              </html>
            `);
            printWindow.document.close();
            printWindow.print(); // Open print dialog for the content
          }
        }
      };
      
      
      
      
      
      

      const handleClose =()=>{
        navigate('/invoicelist');
      }

      const calculateTotalTaxes = () => {
        let totalCGST = 0;
        let totalSGST = 0;
        let totalIGST = 0;
        let totalTaxableAmount = 0;
        let totalValue = 0;
      
        if (invoiceData.products && JSON.parse(invoiceData.products).products) {
          JSON.parse(invoiceData.products).products.forEach(product => {
            const productTaxableAmount = parseFloat(product.quantity) * parseFloat(product.unit_price);
            const productCGST = (parseFloat(product.cgst) * productTaxableAmount) / 100;
            const productSGST = (parseFloat(product.sgst) * productTaxableAmount) / 100;
            const productIGST = (parseFloat(product.igst) * productTaxableAmount) / 100;
            totalCGST += productCGST;
            totalSGST += productSGST;
            totalIGST += productIGST;
            totalTaxableAmount += productTaxableAmount;
          });
        }
         totalValue = totalCGST+totalSGST+totalIGST+totalTaxableAmount;
         
        return {
          totalCGST: totalCGST.toFixed(2),
          totalSGST: totalSGST.toFixed(2),
          totalIGST: totalIGST.toFixed(2),
          totalTaxableAmount : totalTaxableAmount.toFixed(2),
          totalValue : totalValue.toFixed(2),
        };
       
      };
      
      // Calculate the total CGST and SGST
      const { totalCGST, totalSGST,totalIGST,totalTaxableAmount,totalValue } = calculateTotalTaxes();
      

      // const totalAmount = invoiceData.grand_total;
      // console.log(totalAmount);
      // const taxRate = parseFloat(invoiceData['products'][0]['cgst']) // Assuming tax rate is 9%
      // console.log(taxRate);
      // const taxableAmount = totalAmount / (1 + taxRate);
      // const cgst = taxableAmount * taxRate;
      // const sgst = taxableAmount * taxRate;

  return (
    <div>
      <InvoiceContainer id="invoice-container">
        <InvoiceHeader>
          <h1>Tax Invoice</h1>
          {/* <div style={{fontWeight:'bold'}}>{invoiceData.user_detail && JSON.parse(invoiceData.user_detail).user.firm_name}</div> */}

        </InvoiceHeader>
        <BoxedParagraph>
          <Box>
            <p style={{ marginLeft: '10px' }}><b>Invoice No:</b> {invoiceData.invoice_number}</p>
          </Box>
          <Box>
            <p style={{ flex: '0 0 auto', textAlign: 'right', marginRight: '10px' }}><b>Date: </b>{invoiceData.invoice_date_time}</p>
          </Box>
        </BoxedParagraph>
        <InfoContainer>

          <InfoSection>
            <InfoBox>
              <Paragraph><b>Buyer:</b> {invoiceData.customer_name}</Paragraph>
              <Paragraph><b>Address: </b>{invoiceData.customer_address}</Paragraph>
              <Paragraph><b>Pincode: </b>{invoiceData.customer_address}</Paragraph>
              <Paragraph><b>Email: </b>{invoiceData.customer_email}</Paragraph>
              <Paragraph><b>Phone: </b>{invoiceData.customer_mobile}</Paragraph>
              <Paragraph><b>GST No: </b>{invoiceData.customer_gstno}</Paragraph>
            </InfoBox>
          </InfoSection>

          <VerticalLine />
          <InfoSection>
            <InfoBox>
              {/* Add user information here */}
              {/* Modify this section as needed for the user's information */}
              <Paragraph><b>{invoiceData.user_detail && JSON.parse(invoiceData.user_detail).user.firm_name}</b></Paragraph>
              <Paragraph>Prop - {invoiceData.user_detail && JSON.parse(invoiceData.user_detail).user.authorised_person}</Paragraph>
              <Paragraph>{invoiceData.user_detail && JSON.parse(invoiceData.user_detail).user.address}</Paragraph>
              <Paragraph>{invoiceData.user_detail && JSON.parse(invoiceData.user_detail).user.gst_no}</Paragraph>
              <Paragraph><b>State:</b> ...<b>,Code:</b>..</Paragraph>
              <Paragraph>{invoiceData.user_detail && JSON.parse(invoiceData.user_detail).user.mobile}</Paragraph>
              {/* Add more user information as required */}
            </InfoBox>
          </InfoSection>

        </InfoContainer>

        <ItemsTable>
          <h2>Items:</h2>
          <StyledTable>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Description</th>
                <th>HSN </th>
                <th>Tax Rate</th>
                <th>Unit Price</th>
                <th>Quantity</th>
                <th>Taxable Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData.products && JSON.parse(invoiceData.products).products && JSON.parse(invoiceData.products).products.map((product, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{product.product_name}</td>
                  <td>{product.hsn_code}</td>
                  <td>{product.cgst}</td>
                  <td>{product.unit_price}</td>
                  <td>{product.quantity}</td>
                  {/* Calculate and display the total cost for each product */}
                  <td>{(parseFloat(product.quantity) * parseFloat(product.unit_price)).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </ItemsTable>
        <div style={{ display: 'flex' }}>
          <TaxTable style={{ marginRight: '5px' }}>
            <tbody>
              <tr>
                <td style={{ marginBottom: '10px' }}>
                  <b>Total Amount</b> (in words) :
                  {numberToWords(totalValue)}
                </td>
              </tr>
              <tr>
                <td><u><b>Bank Details:</b></u>
                  <br />
                  A/c Holder Name: {invoiceData.bank && JSON.parse(invoiceData.bank).bank.holder_name}  <br />
                  Bank Name : {invoiceData.bank && JSON.parse(invoiceData.bank).bank.bank_name}<br />
                  A/c No : {invoiceData.bank && JSON.parse(invoiceData.bank).bank.bank_account}<br />
                  Branch & IFSC Code : {invoiceData.bank && JSON.parse(invoiceData.bank).bank.branch_name} & {invoiceData.bank && JSON.parse(invoiceData.bank).bank.bank_ifsc}
                </td>
              </tr>

            </tbody>
          </TaxTable>

          <TaxTable>
            <tbody>
              <tr>
                <th>Taxable Amount</th>
                <td>₹ {totalTaxableAmount}</td>
              </tr>
              <tr>
                <th>CGST</th>
                <td>₹ {totalCGST}</td>
              </tr>
              <tr>
                <th>SGST</th>
                <td>₹ {totalSGST}</td>
              </tr>
              <tr>
                <th>IGST</th>
                <td>₹ {totalIGST}</td>
              </tr>
              <tr>
                <th>Total Amount</th>
                <td>₹ {totalValue}</td>
              </tr>
            </tbody>
          </TaxTable>
        </div>
        <div style={{ display: 'flex' }}>
          <TaxTable>
            <tbody>
              <tr><td>Company PAN : {/*required pan data */}
                {/* {invoiceData.user_detail && JSON.parse(invoiceData.user_detail).user.pan}  */}
              </td></tr>
              <tr>

                <td> <b>Declaration:</b> We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.<br />
                  <b>* SUBJECT TO BALLIA JURISDICTION.</b>
                </td>

              </tr>
            </tbody>
          </TaxTable>
          <TaxTable style={{ marginLeft: '5px' }}>
            <thead>
            <tr>
    <th colSpan="2">Authorized Seal & Signature:</th>
  </tr>
            </thead>
            <tbody>

              <tr>
                <td>
                  {invoiceData.user_detail && (
                    <img
                      src={JSON.parse(invoiceData.user_detail).user.signature}
                      alt="Authorized Signature"
                      style={{ maxWidth: '100%', maxHeight: '100px' }} // Adjust the dimensions as needed
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </TaxTable>
        </div>

      </InvoiceContainer>
        <ButtonsContainer>
    
      <PrintButton onClick={handlePrint}>Print</PrintButton>
     
   
        <CloseButton onClick={handleClose}>Close</CloseButton>
      </ButtonsContainer>
     
</div>
  );
};


const InvoiceContainer = styled.div`
font-family: Arial, sans-serif;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box; /* Ensures padding is included in the width calculation */

  @media (max-width: 768px) {
    min-width: 100%; /* For smaller screens, take full width */
  }
  @media (min-width: 769px) {
    max-width: 1200px; /* For larger screens, adjust the max-width as needed */
    margin: 0 auto; /* Reset margin for larger screens if needed */
  }
`;

const InvoiceHeader = styled.div`
  text-align: center;
  margin-bottom: 10px;

  h1 {
    font-size: 2rem;
    margin-bottom: 5px;
  }

  p {
    font-size: 1.1rem;
    margin: 5px 0;
  }
`;

const BoxedParagraph = styled.div`
  display: flex;
  border: 1px solid #000;
  border-radius: 8px;
  overflow: hidden;

  
`;

const Box = styled.div`
  flex: 1;
  padding: 5px;
  p {
    font-size: 1.1rem;
    margin: 5px 0;
  }
  
`;


const InfoContainer = styled.div`
display: flex;
  border: 2px solid #000;
  border-radius: 8px;
  overflow: hidden;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    width: 100%; /* For smaller screens, take full width */
  }
  @media (min-width: 769px) {
    min-width: 50%; /* For larger screens, take 50% width */
  }
`;

const InfoBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoBox = styled.div`
  width: 100%; /* Adjust width as needed */
  border: 1px solid #000;
  border-radius: 8px;
  padding: 20px;
`;

const InfoSection = styled.div`
  flex: 1;
  padding: 2px;
  margin: 0;
`;

const Paragraph = styled.p`
  margin: 0; /* Reset margin for paragraphs */
`;

const VerticalLine = styled.div`
  width: 2px;
  background-color: #000;
`;

const ItemsTable = styled.div`
  margin-bottom: 20px;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }
`;



const TaxTable = styled.table`
  width: 50%;
  border-collapse: collapse;
  margin-bottom: 20px;
  float: right;
  td, th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;

  }

  th {
    background-color: #f2f2f2;
    font-weight:bold;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 10px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-left: 2rem;
  margin-right: 1rem

  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px; 
`;

// const PrintButton = styled(Button)`
//   background-color: #4caf50;
//   color: white;
// `;

const PrintButton = styled(Button)`
  background-color: #008CBA; /* Change color as desired */
  color: white;
`;
const CloseButton = styled(Button)`
  background-color: #f44336;
  color: white;
`;
// const TotalAmount = styled.p`
//   font-size: 1.2rem;
//   font-weight: bold;
//   text-align: right;
// `;


export default Invoice;
