import React, { useState,useEffect }  from 'react';
import styled from 'styled-components';
import OpenNotificationWithIcon from '../helper';
import axios from 'axios';
import apis from '../../src/Api';

const Profile = () => {
  const [profileData, setProfileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState('');
  const [profileImage, setProfileImage] = useState(profileData.profileImage);
  const [signatureImage, setSignatureImage] = useState(profileData.signatureImage);
  // const profileData = {
  //   name: 'John Doe',
  //   address: '123 Main Street, City, Country',
  //   gstNo: 'GST123456789',
  //   proprietorName: 'Jane Doe',
  //   bankDetails: {
  //     accountHolder: 'John Doe',
  //     bankName: 'ABC Bank',
  //     accountNumber: '0123456789',
  //     branch: 'Main Branch',
  //     IFSC: 'ABCD0123456',
  //   },
  //   profileImage: 'path/to/profile/image.jpg',
  //   signatureImage: 'path/to/signature/image.jpg',
  // };

  const getProfile = (params={}) => {
    setLoading(true);
    try{
      const token = localStorage.getItem('billgentoken');
      let values ={
        'token' : token 
      };
      axios.post(
        apis.GET_PROFILE,values,{headers:{'Content-Type': 'multipart/form-data'}
      }
      )
      .then(res => {
        let tableData = res.data;
        setProfileData(tableData.data);
        setLoading(false);
        setPagination({
          ...params.pagination,
          total :5,
        });
      }
        )
    }
    catch(err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProfile({
      pagination,
    });
    
  }, []);

  const handleFileInputChange = (event, field) => {
    const file = event.target.files[0];
    try {
      const token = localStorage.getItem('billgentoken');
    let values ={
      'token': token,
      [field] : file.name,
      };
      axios.post(apis.UPDATE_PROFILE, values, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(res => {
        if (res.status === 200) {
        
          OpenNotificationWithIcon('success', 'Data Added', 'Student Data Added');
        } else {
          OpenNotificationWithIcon('error', 'Error', 'Something Went Wrong');
        }
      })
  } catch (err) {
    console.log(err);
  }
};

    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     console.log(reader.result); 
    //     setProfileData({ ...profileData, [field]: reader.result });

    //   };
      
    //   reader.readAsDataURL(file);
      
    // }
  


  // const handleFileInputChange = (values) => {
  //   try {
  //     values.token = localStorage.get('libraryToken');
      // console.log(values);
      // formData.append('token', values.token);
      // formData.append('qr_name', values.qr_name);
      // formData.append('payment_qr', values.payment_qr[0].originFileObj);
      // axios.post(apis.QRPAYMENT_CODE, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      //   .then(res => {
      //     if (res.status === 200) {
      //       form.resetFields();
      //       openNotificationWithIcon('success', 'Data Added', 'Payment QR Code Added');
      //     } else {
      //       openNotificationWithIcon('error', 'Error', 'Something Went Wrong');
      //     }
      //   })
  //   } catch (err) {
  //     console.log(err);
  //   }

  // };
 


  const handleSignatureImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setSignatureImage(event.target.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  return (
    <ProfileContainer>
      <h1>Profile Page</h1>
      <ProfileContent>
        <ProfileInfo>
          <p><b>Firm Name:</b> {profileData.firm_name}</p>
          <p><b>Proprietor:</b> {profileData.authorised_person}</p>
          <p><b>Address:</b> {profileData.address}</p>
          <p><b>GST Number:</b> {profileData.gst_no}</p>
          <p><b>Email Id: </b>{profileData.email}</p>
          <p><b>Contact no:</b> {profileData.mobile}</p>
          <BankDetails>
            <h3>Bank Details</h3>
            <p><b>Account Holder:</b> {profileData.bank && JSON.parse(profileData.bank).bank.holder_name}</p>
            <p><b>Bank Name: </b>{profileData.bank && JSON.parse(profileData.bank).bank.bank_name}</p>
            <p><b>Account Number:</b>{profileData.bank && JSON.parse(profileData.bank).bank.bank_account}</p>
            <p><b>Branch: </b>{profileData.bank && JSON.parse(profileData.bank).bank.branch_name}</p>
            <p><b>IFSC: </b>{profileData.bank && JSON.parse(profileData.bank).bank.bank_ifsc}</p>
          </BankDetails>
        </ProfileInfo>
        <ImagesSection>
        <ImageLabel>Profile Image:</ImageLabel>
          <ProfileImage src={profileData.profileImage} alt="Profile" />
          <input type="file" accept="image/*" onChange={(event) => handleFileInputChange(event, 'profile_photo')} />
          <ImageLabel>Signature:</ImageLabel>
          <SignatureImage src={profileData.signatureImage} alt="Signature" />
          <input type="file" accept="image/*" onChange={(event) => handleFileInputChange(event, 'signature')} />
        </ImagesSection>
      </ProfileContent>
    </ProfileContainer>
  );
};

const ProfileContainer = styled.div`
  font-family: Arial, sans-serif;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
`;

const ProfileContent = styled.div`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ProfileInfo = styled.div`
 
  padding: 10px;
  border-right: 1px solid #ccc;
  width: 60% ;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.1rem;
    margin: 8px 0;
  }


  @media (max-width: 768px) {
   border:none;
   width: 100% ;
  }
  
`;

const BankDetails = styled.div`
  margin-top: 20px;

  h3 {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    margin: 5px 0;
  }
`;

const ImagesSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;
const ImageLabel = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
`;
const ProfileImage = styled.img`
  max-width: 300px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SignatureImage = styled.img`
  max-width: 300px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export default Profile;
