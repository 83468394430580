import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button} from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import apis from '../../Api';

const Dashboard = () => {
  const [totalInvoice, setTotalInvoice]= useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    fetchData();
  },[]);
 
  const fetchData = (params= {} ) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('billgentoken');
      let values ={
      'token': token
      };
      console.log(token);
      axios.post(
        apis.INVOICE_LIST,  // Assuming this is your URL
        values,  // No data in this case, since it's a GET request
        {
          headers: {
            // 'Authorization': `${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(res => {
          let tableData = res.data.data.length;
          setTotalInvoice(tableData);
          console.log(res.data.data.length);
    });
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  console.log(totalInvoice);
};
  
  const data = [
    { title: 'Single Invoice', value: totalInvoice, viewLink: '/invoicelist', createLink: '/createinvoice'},
    { title: 'Bulk Invoice', value: totalInvoice, viewLink: '/invoicelist', createLink: '/bulkinvoice' },
    { title: 'Total Bill', value: totalInvoice },
  ];

  return (
    <Container sx={{ marginTop: 4 }}>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item key={index} xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5">{item.title}</Typography>
                <Typography variant="h4">{item.value}</Typography>
                <Button component={Link} to={item.viewLink} variant="contained" color="primary" size="small" style={{ marginRight: '8px' }}>View</Button>
                {index < 2 && (
                  <React.Fragment>
                    <Button component={Link} to={item.createLink} variant="contained" color="secondary" size="small">Create</Button>
                  </React.Fragment>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Dashboard;




// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import {
//   Drawer,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   AppBar,
//   Toolbar,
//   Typography,
// } from '@material-ui/core';
// import {
//   Dashboard as DashboardIcon,
//   Person as PersonIcon,
//   Mail as MailIcon,
// } from '@material-ui/icons';
// import { useNavigate } from 'react-router-dom';

// const drawerWidth = 240;



// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//   },
//   appBar: {
//     width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: drawerWidth,
//   },
//   drawer: {
//     width: drawerWidth,
//   },
//   drawerPaper: {
//     width: drawerWidth,
//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3),
//   },
// }));

// const Dashboard = () => {
//   const classes = useStyles();
//   const navigate= useNavigate();
//   const handleLogoutClick = () => {
//     localStorage.removeItem('billgentoken');
//     navigate('/');
//   };

//   return (
//     <div className={classes.root}>
//         <Toolbar>
//           <Typography variant="h6">Dashboard</Typography>
//         </Toolbar>

//       <Drawer
//         className={classes.drawer}
//         variant="permanent"
//         classes={{
//           paper: classes.drawerPaper,
//         }}
//         anchor="left"
//       >
//         <Toolbar />
//         <List>
//           <ListItem button>
//             <ListItemIcon>
//               <DashboardIcon />
//             </ListItemIcon>
//             <ListItemText primary="Dashboard" />
//           </ListItem>
//           <ListItem button>
//             <ListItemIcon>
//               <PersonIcon />
//             </ListItemIcon>
//             <ListItemText primary="Profile" />
//           </ListItem>
//           <ListItem button>
//             <ListItemIcon>
//               <MailIcon />
//             </ListItemIcon>
//             <ListItemText primary="Messages" />
//           </ListItem>
//         </List>
//       </Drawer>

//       <main className={classes.content}>
//         <Toolbar />
//         <Typography variant="h4">Welcome to the Dashboard</Typography>
//       </main>
//     </div>
//   );
// };

// export default Dashboard;
