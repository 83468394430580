import React from 'react';
import { Snackbar } from '@mui/material';

const OpenNotificationWithIcon = ({ type, title, msg, open, onClose }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      message={`${title}: ${msg}`}
    />
  );
};

export default OpenNotificationWithIcon;

