let base_url = `${process.env.REACT_APP_BASE_API_URL}`;

module.exports = {
    BASE_URL: base_url,
    ADMIN_LOGIN: base_url + 'login',
    PRODUCT_LIST: base_url + 'productList',
    ADD_PRODUCT: base_url + 'saveProduct',
    INVOICE_LIST: base_url + 'invoiceList',
    GET_INVOICE :base_url + 'getinvoice',
    ADD_INVOICE: base_url + 'addinvoice',
    GET_PROFILE: base_url + 'getprofile',
    UPDATE_PROFILE: base_url + 'updateprofile'
};