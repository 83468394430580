import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './component/login/Login';
import Display from './component/dashboard/display';
import Dashboard from './component/dashboard/Dashboard';
import ProductList from './component/Products/ProductList';
import AddProduct from './component/Products/AddProduct';
import InvoiceList from './component/invoice/InvoiceList';
import CreateInvoice from './component/invoice/CreateInvoice';
import Profile from './component/CProfile';
import Invoice from './component/invoice/invoiceViewPage/Invoice';

function App() {
  return (
   <BrowserRouter>
   <Routes>
    <Route path='/' element={<Login/>}/>
    <Route path ="/dashboard" element={<Display><Dashboard/></Display>} />
    <Route path ="/productlist" element={<Display><ProductList/></Display>} />
    <Route path ="/addproduct" element={<Display><AddProduct/></Display>} />
    <Route path ="/invoicelist" element={<Display><InvoiceList/></Display>} />
    <Route path ="/createinvoice" element={<Display><CreateInvoice/></Display>} />
    <Route path ="/profile" element={<Display><Profile/></Display>} />
    <Route path ="/invoice" element={<Display><Invoice/></Display>} />
    </Routes>
   </BrowserRouter>
  );
}

export default App;
