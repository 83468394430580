import { Search} from '@mui/icons-material';
import { Link,useNavigate } from 'react-router-dom';
import React, { useRef,useState, useEffect, useReducer } from 'react';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Pagination from '@mui/material/Pagination';
import axios from 'axios';
import apis from '../../Api';
import Box from '@mui/material/Box';
import Highlighter from 'react-highlight-words';


// Reducer function
const ProductList = () => {
  const searchInput = useRef(null);
  const [data, setData] = useState([]);
  const [pid, setPId] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [pagination, setPagination] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // Rest of the code remains the same...

  // Update handleSearch function to dispatch actions
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  // Update handleReset function to dispatch actions
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  
  const handleEdit = (row) => {
    navigate('/addproduct', { state: { rowData: row } });
    console.log(row);
  }
  
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }}>
        <InputBase
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onKeyDown={(e) => e.stopPropagation()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Stack>
          <Button
            variant="contained"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            startIcon={<Search />}
            size="small"
            sx={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            sx={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            variant="text"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            variant="text"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Stack>
      </div>
    ),
    filterIcon: (filtered) => (
      <Search
        sx={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.focus(), 100);
      }
    },
    render: (text) =>
    searchedColumn === dataIndex ? (
      <Box bgcolor="#ffc069" p={0}>
        {text ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : null}
      </Box>
      ) : (
        text
      ),
  });
  
  // Update fetchData function to use dispatch for loading state
  const fetchData = (params= {} ) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('billgentoken');
      let values ={
      'token': token
      };
      console.log(token);
      axios.post(
        apis.PRODUCT_LIST,  // Assuming this is your URL
        values,  // No data in this case, since it's a GET request
        {
          headers: {
            // 'Authorization': `${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(res => {
          let tableData = res.data;
          console.log(tableData.data);
          setData(tableData.data);  
          setLoading(false);
          setPagination({
            ...params.pagination,
            total: 5,
            });
          })
    } 
     catch (err) {
       console.log('Error:', err);
       setLoading(false);
    }
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, []);

  
  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      width: '20%',
      sorter: (a, b) => a.authorname.length - b.authorname.length,
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('product_name'),
    },
    {
      title: 'HSN',
      dataIndex: 'hsn_code',
      key: 'hsn',
      width:'8%',
    },
    {
      title: 'SGST',
      dataIndex: 'sgst',
      key: 'sgst',

    },
    {
      title: 'CGST',
      dataIndex: 'sgst',
      key: 'sgst',

    },
    {
      title: 'IGST',
      dataIndex: 'igst',
      key: 'igst',

    },
    {
      title: 'Unit Price',
      dataIndex: 'unit_price',
      key: 'unit_price',  

    },
    {
      title: 'Stock Quantity',
      dataIndex: 'stock_quantity',
      key: 'stock_quantity',

    },
  ];

  return (
    <>
      <Link to="/addproduct" style={{ float: 'right',margin:'2px' }}>
        <Button variant="contained" startIcon={<AddBoxIcon />} size="large">
          Add Products
        </Button>
      </Link>
      <h2>Product List</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead style={{ borderTop: '2px solid #000' }} >
            <TableRow>
            <TableCell key="s_no" align="left" style={{fontWeight:'bold'}}>S no.</TableCell>
              {columns.map((column) => (
                <TableCell key={column.key} align="left" style={{fontWeight:'bold'}} >
                  {column.title}
                </TableCell>
              ))}
              <TableCell key="update" align="left" style={{fontWeight:'bold'}}>Update/Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {Array.isArray(data) && data.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell align="left">
                  {index + 1}
                </TableCell>
                {columns.map((column) => (
                  <TableCell key={column.key} align="left">
                    {row[column.dataIndex]}
                  </TableCell>
                ))}
                  <TableCell align="left">
            <Box display="flex" justifyContent="flexx-start">
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => handleEdit(row)}
              >
                Edit
              </Button>
            </Box>
          </TableCell>
        </TableRow>
            ))}
        </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProductList;
