import { Search, GetApp, AddToPhotosSharp } from '@mui/icons-material';
import { Link,useNavigate } from 'react-router-dom';
import React, { useRef,useState, useEffect, useReducer } from 'react';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import axios from 'axios';
import apis from '../../Api';
import Box from '@mui/material/Box';
import Highlighter from 'react-highlight-words'; // Assuming you're using react-highlight-words for highlighting
import OpenNotificationWithIcon from '../Helper';


// Reducer function
const InvoiceList = () => {
  const searchInput = useRef(null);
  const [data, setData] = useState([]);
  const [pid, setPId] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [pagination, setPagination] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // Rest of the code remains the same...

  // Update handleSearch function to dispatch actions
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  // Update handleReset function to dispatch actions
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  
  // const handleEdit = (row) => {
  //   navigate('/addproduct', { state: { rowData: row } });
  //   console.log(row);
  // }
  const handleView = (invoice_date_time, id) => {
    navigate('/invoice', { state: { invoice_id:id } });
    
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }}>
        <InputBase
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onKeyDown={(e) => e.stopPropagation()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Stack>
          <Button
            variant="contained"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            startIcon={<Search />}
            size="small"
            sx={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            sx={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            variant="text"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            variant="text"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Stack>
      </div>
    ),
    filterIcon: (filtered) => (
      <Search
        sx={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.focus(), 100);
      }
    },
    render: (text) =>
    searchedColumn === dataIndex ? (
      <Box bgcolor="#ffc069" p={0}>
        {text ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : null}
      </Box>
      ) : (
        text
      ),
  });
  
  // Update fetchData function to use dispatch for loading state
  const fetchData = (params= {} ) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('billgentoken');
      let values ={
      'token': token
      };
      console.log(token);
      axios.post(
        apis.INVOICE_LIST,  // Assuming this is your URL
        values,  // No data in this case, since it's a GET request
        {
          headers: {
            // 'Authorization': `${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(res => {
          let tableData = res.data;
          if (Array.isArray(tableData.data)) {
          tableData.data.forEach(item => {
            if (item.invoice_date_time) {
              const invoiceDateTime = new Date(item.invoice_date_time);

              // Extract day, month, and year
              const day = invoiceDateTime.getDate().toString().padStart(2, '0');
              const month = (invoiceDateTime.getMonth() + 1).toString().padStart(2, '0');
              const year = invoiceDateTime.getFullYear();

              // Format date as 'dd-mm-yyyy'
              item.invoice_date_time = `${day}-${month}-${year}`;
            }

            if (item.products) {
              const parsedProducts = JSON.parse(item.products).products;
              const totalQuantity = parsedProducts.reduce((total, product) => {
                return total + parseInt(product.quantity, 10);
              }, 0);
              // Calculate the sum of 'cgst', 'sgst', and 'grand_total' for products in each item
              let totalCGST = 0;
              let totalSGST = 0;
              let totalIGST = 0;
              let taxableAmount = 0;
              let totalTax = 0;
              let grandTotal = 0;
        
              parsedProducts.forEach(product => {
                totalCGST = parseFloat(product.cgst);
                totalSGST = parseFloat(product.sgst);
                totalIGST = parseFloat(product.igst);
                grandTotal += parseFloat(product.total);
              });
              
              // Set the totals to new properties in each item
              console.log(grandTotal);
              const taxes = (((totalCGST+totalSGST+totalIGST)*grandTotal)/100);
              item.taxableAmount = (grandTotal).toFixed(2);
              item.totalTax = (((totalCGST+totalSGST+totalIGST)*grandTotal)/100).toFixed(2);
              item.grandTotal = (grandTotal+taxes).toFixed(2);
              item.totalQuantity = totalQuantity;
            }
          });
          
          setData(tableData.data);  
        } else {
          setData([]);
        }
          setLoading(false);
          setPagination({
            ...params.pagination,
            total: 5,
            });
          })
    } 
     catch (err) {
       console.log('Error:', err);
       setLoading(false);
    }
  };

  useEffect(() => {
    fetchData({
      pagination,loading
    });
  }, []);

  
  const columns = [
     {
      title: 'Invoice No',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      width:'2%',
      render: (text, record, index) => `${parseInt(text, 10) + 1}`,
    },
    {
      title: 'Date',
      dataIndex: 'invoice_date_time',
      key: 'invoice_date_time',
      width:'2%',
    },
    {
      title: 'Customer Name',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: '25%',
      sorter: (a, b) => a.authorname.length - b.authorname.length,
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('product_name'),
    },
    // {
    //   title: 'HSN',
    //   dataIndex: 'hsn_code',
    //   key: 'hsn',
    //   width:'8%',
    // },
    
    // {
    //   title: 'CGST',
    //   dataIndex: 'sgst',
    //   key: 'sgst',

    // },
    // {
    //   title: 'IGST',
    //   dataIndex: 'sgst',
    //   key: 'sgst',

    // },
    // {
    //   title: 'Unit Price',
    //   dataIndex: 'unit_price',
    //   key: 'unit_price',  

    // },
    {
      title: 'Total Quantity',
      dataIndex: 'totalQuantity',
      key: 'totalQuantity',

    },
    {
      title: 'Taxable Amount',
      dataIndex: 'taxableAmount',
      key: 'taxableAmount',

    },
    {
      title: 'Total Tax',
      dataIndex: 'totalTax',
      key: 'totalTax',

    },
    {
      title: 'Grand Total',
      dataIndex: 'grandTotal',
      key: 'grandTotal',

    },
  ];

  return (
    <>
       <Link to="/bulkinvoice" style={{ float: 'right',margin:'2px' }}>
        <Button variant="contained" startIcon={<AddToPhotosIcon />} size="large">
         Bulk Create
        </Button>
      </Link>
      <Link to="/createinvoice" style={{ float: 'right',margin:'2px' }}>
        <Button variant="contained" startIcon={<AddBoxIcon />} size="large">
         Create
        </Button>
      </Link>
      <h2>Invoice</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead style={{ borderTop: '2px solid #000' }} >
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.key} align="left" style={{fontWeight:'bold',marginLeft:'3px'}} >
                  {column.title}
                </TableCell>
              ))}
              <TableCell key="update" align="left" style={{fontWeight:'bold'}}>View Invoice</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
          {Array.isArray(data) ? (
  data.map((row, index) => (
              <TableRow key={row.id}>
                
                {columns.map((column) => (
                  <TableCell key={column.key} align="left">
                    {row[column.dataIndex]}
                  </TableCell>
                ))}
                  <TableCell align="left">
            <Box display="flex" justifyContent="flexx-start">
              {/* <Button 
                variant="contained" 
                color="primary"
                onClick={() => handleEdit(row)}
              >
                Edit
              </Button> */}
              <Button 
                variant="contained" 
                color="primary"
                style={{marginLeft:'2px'}}
                onClick={() => handleView(row.invoice_date_time,row.id)}

              >
                View
              </Button>
            </Box>
          </TableCell>
        </TableRow>
            ))
            ):(
              <p>Error: Data is not an array or is undefined/null.</p>
            )
          }
        </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default InvoiceList;
