import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, TextField, Input, FormControl, InputLabel, Select, Button, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useState, useEffect } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import openNotificationWithIcon from '../../helper';
import axios from 'axios';
import apis from '../../Api';


const CreateInvoice = () => {
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedProductID, setSelectedProductID] = useState('');
  const [productName, setProductName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [fields, setFields] = useState([{ id: '', product_name: '', quantity: '' }]);
  const [products, setProducts] = useState([]);
  const [laoding, setLoading] = useState(false);
  const [pagination, setPagination] = useState('');
  const [productid, setProductID] = useState([]);
  const [defaultDate, setDefaultDate] = useState('');
  const [invoice_number, setInvoice_Number] = useState(null);
  const [customerDetails, setCustomerDetails] = useState({
    customerName: '',
    address: '',
    mobileNumber: '',
    gstNumber: '',
    date: '',
  });
  const navigate = useNavigate();

  const formProps = {};  // Replace with appropriate form props for MUI

  const companySelectProps = {};  // Replace with appropriate select props for MUI

  const contactSelectProps = {};  // Replace with appropriate select props for MUI

  const missionSelectProps = {};  // Replace with appropriate select props for MUI
  const [selectedDate, setSelectedDate] = useState(null);

  const resetFields = () => {
    // Clear the state values associated with your form fields
    setFields([{ id: '', product_name: '', quantity: '' }]);
  };



  const handleProductIDChange = (event, index) => {
    const selectedID = event.target.value;
    setSelectedProductID(selectedID);

    const selectedProduct = productid.find(item => item.id === selectedID);
    
    const updatedFields = [...fields];
    updatedFields[index] = { ...updatedFields[index], id: selectedID };
    updatedFields[index] = { ...updatedFields[index], product_name: selectedProduct.product_name };
    updatedFields[index] = { ...updatedFields[index], tax_slab: selectedProduct.tax_slab };
    setFields(updatedFields);
  };

  const handleQuantityChange = (event, index) => {
    const newQuantity = event.target.value;

    // Update the corresponding field in the state
    const updatedFields = [...fields];
    updatedFields[index] = { ...updatedFields[index], quantity: newQuantity };
    setFields(updatedFields);
  };

  const addNewField = () => {
    setFields([...fields, { id: '', product_name: '', tax_slab: '', quantity: '' }]);
  };
  const removeField = () => {
    if (fields.length > 0) {
      const updatedFields = [...fields]; // Create a copy of fields
      updatedFields.pop(); // Remove the last element from the copied array
      setFields(updatedFields); // Update state with the modified array
    }
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClose = () => {

  }

  const handleCustomerInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails({
      ...customerDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (values) => {
    values.preventDefault();

    try {
      const areTaxSlabsSame = fields.every((field, index) => index === 0 || field.tax_slab === fields[index - 1].tax_slab);
      if (!areTaxSlabsSame) {
        openNotificationWithIcon('error', 'Error', 'Please enter same tax rate value for all products');

        return; // Exit function if tax slabs are not the same
      }
  
      let values = {
        products: JSON.stringify(fields.map(field => ({
          product_id: field.id,
          quantity: field.quantity,
          tax_slab: field.tax_slab
        }))),
        customer_name: customerDetails.customerName,
        customer_address: customerDetails.address,
        customer_mobile: customerDetails.mobileNumber,
        customer_gstno: customerDetails.gstNumber,
        address_pincode :customerDetails.pincode,
        // date: customerDetails.date,
        token: localStorage.getItem('billgentoken')
      };
      axios.post(apis.ADD_INVOICE, values, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(res => {
          if (res.status === 200) {
            openNotificationWithIcon('success', 'Data Added', 'Invoice Generated');
            resetFields();
            
            // Need response of invoiceid from api to set
            setInvoice_Number(res.data.data);
             navigate('/invoice', { state: { date: customerDetails.date, invoice_id: res.data.data } });
          } else {
            openNotificationWithIcon('error', 'Error', 'Something Went Wrong');
          }
        })
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    console.log(today,dd,mm,yyyy);
    const formattedDate = `${yyyy}/${mm}/${dd}`;
    
    setDefaultDate(formattedDate);
   
}, []);


  useEffect(() => {
    const fetchP_ID = (params = {}) => {
      setLoading(true);
      try {
        const token = localStorage.getItem('billgentoken');

        let values = {
          'token': token
        };
        console.log(token);
        axios.post(
          apis.PRODUCT_LIST,  // Assuming this is your URL
          values,  // No data in this case, since it's a GET request
          {
            headers: {
              // 'Authorization': `${token}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        )
          .then(res => {
            let tableData = res.data;
            if (tableData.status && tableData.data && tableData.data.length > 0) {
              const productData = tableData.data.map(product => ({
                id: product.id,
                product_name: product.product_name,
                tax_slab: `${product.sgst}+${product.cgst}`
              }));
              setProductID(productData);
              productData.forEach(product => {
                console.log(product.tax_slab); // Log tax_slab for each product
              });
            }
            setLoading(false);
            console.log(productid);
            setPagination({
              ...params.pagination,
              total: 5,
            });
          })
      }
      catch (err) {
        console.log('Error:', err);
        setLoading(false);
      }
    };
    fetchP_ID();
    console.log(productid);
  }, []);

  return (
    <div>
      <h2>GENERATE INVOICE</h2>
      <form {...formProps} style={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit}>
        <h4 style={{ margin: 0, marginLeft: '10px' }}>Customer Info :</h4>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              label="Mobile No"
              name="mobileNumber"
              variant="outlined"
              fullWidth
              value={customerDetails.mobileNumber}
              onChange={handleCustomerInputChange}
            />
          </Grid>
          
          <Grid item xs={2}>
            <TextField
              label="Customer Name"
              name="customerName"
              variant="outlined"
              fullWidth
              value={customerDetails.customerName}
              onChange={handleCustomerInputChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Address"
              name="address"
              variant="outlined"
              fullWidth
              value={customerDetails.address}
              onChange={handleCustomerInputChange}
            />
          </Grid>
          <Grid item xs={1.5}>
            <TextField
              label="Pincode"
              name="pincode"
              variant="outlined"
              fullWidth
              value={customerDetails.address_pincode}
              onChange={handleCustomerInputChange}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              label="GST No"
              name="gstNumber"
              variant="outlined"
              fullWidth
              value={customerDetails.gstNumber}
              onChange={handleCustomerInputChange}
            />
          </Grid>


        </Grid>
        <h4 style={{ marginBottom: 0, marginLeft: '15px' }}>Product Details</h4>

        <Grid container spacing={2} padding={3}>
          {fields.map((field, index) => (
            <Grid container spacing={2} key={index} marginBottom={2}>
              <Grid item xs={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Product ID</InputLabel>
                  <Select
                    label="Product ID"
                    fullWidth
                    value={field.id}
                    onChange={(e) => handleProductIDChange(e, index)}
                  >
                    {productid.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="Product Name"
                  name={`product_name_${index}`}
                  variant="outlined"
                  fullWidth
                  value={field.product_name}
                  readOnly
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Tax Slab"
                  name={`tax_slab_${index}`}
                  variant="outlined"
                  fullWidth
                  value={field.tax_slab}
                  readOnly
                  InputLabelProps={{shrink: true, }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="Quantity"
                  name={`quantity_${index}`}
                  variant="outlined"
                  fullWidth
                  value={field.quantity}
                  required
                  onChange={(e) => handleQuantityChange(e, index)}
                />
              </Grid>
            </Grid>
          ))}

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginBottom: '20px' }}>
              <Button variant="outlined" onClick={addNewField} ><LibraryAddIcon/> </Button>
              <Button variant="outlined" onClick={removeField} style={{marginLeft:'4px'}}><DeleteIcon/> </Button>
            </div>

            {/* 
                // <Grid item xs={6}>
                //         <LocalizationProvider dateAdapter={AdapterDateFns}>
                //             <DesktopDatePicker */}
            {/* //                 label="Invoice Date"
                //                 inputFormat="MM/dd/yyyy"
                //                 value={selectedDate}
                //                 onChange={handleDateChange}
                //                 renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
                //             />
                //         </LocalizationProvider>
                // </Grid> */}
            <div style={{ marginTop: '10px' }}>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>

              <Link to="/invoiceList">
                <Button type="close" variant="contained" color="primary" style={{ marginLeft: '10px' }}>
                  Close
                </Button>
              </Link>
            </div>
          </div>
        </Grid>
      </form>
    </div>
  );
};

export default CreateInvoice;
