import React, { useState,useEffect } from 'react';
import { Container, TextField, Button, Typography,Grid } from '@mui/material';
import { Label } from '@mui/icons-material';
import { Link,useLocation } from 'react-router-dom';
import axios from 'axios';
import apis from '../../Api';
import OpenNotificationWithIcon from '../Helper';

const AddProduct = () => {
  const [formData, setFormData] = useState({
    product_id: '',
    product_name: '',
    hsn_code: '',
    unit_price: '',
    cgst: '',
    sgst: '',
    igst: '',
    stock_quantity:''
  }); 
  const location = useLocation();
  const rowData = location.state ? location.state.rowData : null;
  useEffect(() => {
  if (rowData) {
    setFormData(rowData);
  }
  }, [rowData])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  

  const handleSubmit = (values) => {
    try {
      values = formData;
      values.token = localStorage.getItem('billgentoken');
      axios.post(apis.ADD_PRODUCT, values, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(res => {
          if (res.status === 200) {
            formData.resetFields();
            OpenNotificationWithIcon('success', 'Data Added', 'Student Data Added');
          } else {
            OpenNotificationWithIcon('error', 'Error', 'Something Went Wrong');
          }
        })
    } catch (err) {
      console.log(err);
    }
  };

  const onFinishFailed = (errorInfo) => {
    OpenNotificationWithIcon('error', 'Form Submission Failed', 'Some of the fields are Required');
  };


  return (
    <Container style={{marginTop:'1rem'}}>
      <form>
        <Typography variant='h4'>Add Product</Typography>

        <Grid container spacing={2} >
         <Grid item xs={6}>
        <TextField
          fullWidth
          margin="normal"
          label="Product Name"
          variant="outlined"
          name="product_name"
          value={formData.product_name}
          onChange={handleChange}
        />
        </Grid>
        <Grid item xs={6}>
        <TextField
          halfWidth
          margin="normal"
          label="HSN Code"
          variant="outlined"
          name="hsn_code"
          value={formData.hsn_code}
          onChange={handleChange}
        />
        </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={4}>
        <TextField
          fullWidth
          margin="normal"
          label="CGST (%)"
          variant="outlined"
          name="cgst"
          value={formData.cgst}
          onChange={handleChange}
        />
        </Grid>
       <Grid item xs={4}> 
        <TextField
          fullWidth
          margin="normal"
          label="SGST (%)"
          variant="outlined"
          name="sgst"
          value={formData.sgst}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={4}>  
        <TextField
        fullWidth
        margin="normal"
        label="IGST (%)"
        variant="outlined"
        name="igst"
        value={formData.igst}
        onChange={handleChange}
      />
      </Grid>
      </Grid>
     <Grid container spacing={2}>
      <Grid item xs={6}>
      <TextField
      fullWidth
      margin="normal"
      label=" Stock Quantity"
      variant="outlined"
      name="stock_quantity"
      value={formData.stock_quantity}
      onChange={handleChange}
    />
    </Grid>
    <Grid item xs={6}>
    <TextField
    fullWidth
    margin="normal"
    label="Unit Price"
    variant="outlined"
    name="unit_price"
    value={formData.unit_price}
    onChange={handleChange}
  />
    </Grid>
    <Grid container spacing={2} alignItems="center" justifyContent="center" style={{marginTop:'2rem'}}>
      
     <Button type="submit" variant="contained" color="primary"  onClick={handleSubmit} >
          Submit
        </Button>
       
       <Link to="/productList"><Button type="close" variant="contained" color="primary" style={{marginLeft:'2rem'}} >
          Close
        </Button>
        </Link>
      
        </Grid>
        </Grid>
      </form>  
    </Container>
 );
};

export default AddProduct;
